import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { saveRequisition } from "../../../store/structure/actions";
import { showError } from "../../../app/toastify";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const AddRequisitionDialog = (props) => {
  const {
    loading,
    showAddRequisitionForm,
    setShowAddRequisitionForm,

    saveRequisition,
    position,
  } = props;

  const history = useHistory();

  const [requisitionForm, setRequisitionForm] = useState({
    positionId: "",
    jobClassificationId: "",
    jobDescriptions: "",
    numberOfPosts: "",
    reason: "",
    isInternal: false,
    willHavePsychometricTest: true,
    willHaveWrittenExam: true,
    willHaveOralExam: true,
    isFromWaitingListRequisition: false,
  });

  const [waitingListRequestForm, setWaitingListRequestForm] = useState({
    positionId: "",
    positionName: "",
    levelId: "",
    scaleId: "",
    numberOfPosts: "",
    reason: "",
  });

  const [showWaitngListForm, setShowWaitngListForm] = useState(false);

  useEffect(() => {
    if (showAddRequisitionForm) {
      setRequisitionForm({
        ...requisitionForm,
        positionId: position.id,
        jobClassificationId: position.jobClassificationId,
        jobDescriptions: position.description,
        numberOfPosts: position.numberOfVacantPosts,
      });

      setWaitingListRequestForm({
        ...waitingListRequestForm,
        positionId: position.id,
        positionName: position.name,
        levelId: position.levelId,
        scaleId: position.scaleId,
        numberOfPosts:
          +position.numberOnWaitingList <= +position.numberOfVacantPosts
            ? position.numberOnWaitingList
            : position.numberOfVacantPosts,
      });

      setShowWaitngListForm(!!position.numberOnWaitingList);
    }
  }, [position, showAddRequisitionForm]);

  const [errors, setErrors] = useState({
    positionHasError: false,
    reasonHasError: false,
    numberOfPostsHasError: false,
    isNumberOfPostsEqualToZero: false,
    numberOfVacantPostsIsLessThanEntered: false,
    examOptionHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      positionHasError: false,
      reasonHasError: false,
      numberOfPostsHasError: false,
      hasError: false,
      numberOfVacantPostsIsLessThanEntered: false,
      examOptionHasError: false,
    };

    if (
      !(showWaitngListForm ? waitingListRequestForm : requisitionForm)
        .numberOfPosts
    ) {
      error.numberOfPostsHasError = true;
      error.hasError = true;
    }
    if (
      (showWaitngListForm ? waitingListRequestForm : requisitionForm)
        .numberOfPosts <= 0
    ) {
      error.isNumberOfPostsEqualToZero = true;
      error.hasError = true;
    }
    if (
      (showWaitngListForm ? waitingListRequestForm : requisitionForm)
        .numberOfPosts > position.numberOfVacantPosts
    ) {
      error.numberOfVacantPostsIsLessThanEntered = true;
      error.hasError = true;
    }

    if (
      !(showWaitngListForm ? waitingListRequestForm : requisitionForm).reason
    ) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    if (
      !(
        requisitionForm.willHaveOralExam || requisitionForm.willHaveWrittenExam
      ) &&
      !showWaitngListForm
    ) {
      error.examOptionHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      // showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveRequisition(
        showWaitngListForm ? waitingListRequestForm : requisitionForm,
        showWaitngListForm ? setWaitingListRequestForm : setRequisitionForm,
        setShowAddRequisitionForm,
        history,
        showWaitngListForm
      );
    }
  };

  const onClose = () => {
    setShowAddRequisitionForm(false);

    setRequisitionForm({
      positionId: "",
      numberOfPosts: "",
      isInternal: false,
      reason: "",
      willHavePsychometricTest: true,
      willHaveWrittenExam: true,
      willHaveOralExam: true,
    });

    setWaitingListRequestForm({
      positionId: "",
      positionName: "",
      levelId: "",
      scaleId: "",
      numberOfPosts: "",
      reason: "",
    });
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAddRequisitionForm}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span className="text-dark">Request to Advertize |</span>{" "}
            {position.name}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="px-3">
            {!!position.numberOnWaitingList && (
              <div className="alert alert-info text-center" role="alert">
                <span className="badge badge-primary mr-1">
                  {position.numberOnWaitingList}
                </span>
                Applicant{position.numberOnWaitingList > 1 ? "s" : ""} for{" "}
                <strong>{position.name}</strong>
                {position.numberOnWaitingList > 1 ? "are" : "is"} available on
                the waiting list.
              </div>
            )}

            {!showWaitngListForm && (
              <div className="row">
                <div className="col-12 mt-3">
                  <TextField
                    fullWidth
                    size="small"
                    rows={4}
                    name="reason"
                    autoFocus
                    label="Number of staff needed"
                    variant="outlined"
                    placeholder="Number of staff needed"
                    value={requisitionForm.numberOfPosts}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        numberOfPostsHasError: false,
                        isNumberOfPostsEqualToZero: false,
                        numberOfVacantPostsIsLessThanEntered: false,
                      });

                      setRequisitionForm({
                        ...requisitionForm,
                        numberOfPosts: e.target.value,
                      });
                    }}
                  />

                  {errors.numberOfPostsHasError && (
                    <small className="text-danger">
                      Number of posts is required{" "}
                    </small>
                  )}
                  {!errors.numberOfPostsHasError &&
                    errors.isNumberOfPostsEqualToZero && (
                      <small className="text-danger">
                        Number of posts must greater than zero
                      </small>
                    )}
                  {errors.numberOfVacantPostsIsLessThanEntered && (
                    <small className="text-danger">
                      Only {position.numberOfVacantPosts} vacant post
                      {position.numberOfVacantPosts > 1 ? "s are " : " is "}
                      available
                    </small>
                  )}
                </div>

                <div className="col-12 mt-3">
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="reason"
                    autoFocus
                    label="Motivate your request"
                    variant="outlined"
                    placeholder="Motivate your request"
                    value={requisitionForm.reason}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setErrors({ ...errors, reasonHasError: false });

                      setRequisitionForm({
                        ...requisitionForm,
                        reason: e.target.value,
                      });
                    }}
                  />

                  {errors.reasonHasError && (
                    <small className="text-danger">
                      Please, motivate your request
                    </small>
                  )}
                </div>

                <div className="col-12">
                  <div className="input-group input-group-sm mt-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        Recruitment Mode
                      </span>
                    </div>
                    <div className="form-control">
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="no"
                          name="isInternal"
                          // value={false}
                          checked={requisitionForm.isInternal === false}
                          onChange={(event) => {
                            const isInternal = false;
                            setRequisitionForm({
                              ...requisitionForm,
                              isInternal,
                            });
                          }}
                        />
                        <label className="form-check-label" htmlFor="no">
                          Open Competition
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="yes"
                          name="isInternal"
                          // value={true}
                          checked={requisitionForm.isInternal === true}
                          onChange={(event) => {
                            const isInternal = true;
                            setRequisitionForm({
                              ...requisitionForm,
                              isInternal,
                            });
                          }}
                        />
                        <label className="form-check-label" htmlFor="yes">
                          Internal Recruitment
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="input-group input-group-sm mt-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        Examination Types
                      </span>
                    </div>
                    <div className="form-control">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="willHavePsychometricTest"
                          name="willHavePsychometricTest"
                          checked={requisitionForm.willHavePsychometricTest}
                          onChange={(event) => {
                            setErrors({ ...errors, examOptionHasError: false });

                            setRequisitionForm({
                              ...requisitionForm,
                              willHavePsychometricTest:
                                !requisitionForm.willHavePsychometricTest,
                            });
                          }}
                        />
                        <label className="form-check-label">
                          PsychometricTest
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="willHaveWrittenExam"
                          name="willHaveWrittenExam"
                          checked={requisitionForm.willHaveWrittenExam}
                          onChange={(event) => {
                            setErrors({ ...errors, examOptionHasError: false });

                            setRequisitionForm({
                              ...requisitionForm,
                              willHaveWrittenExam:
                                !requisitionForm.willHaveWrittenExam,
                            });
                          }}
                        />
                        <label className="form-check-label">Written</label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="willHaveOralExam"
                          name="willHaveOralExam"
                          // value={true}
                          checked={requisitionForm.willHaveOralExam}
                          onChange={(event) => {
                            setErrors({ ...errors, examOptionHasError: false });
                            setRequisitionForm({
                              ...requisitionForm,
                              willHaveOralExam:
                                !requisitionForm.willHaveOralExam,
                            });
                          }}
                        />
                        <label className="form-check-label">Oral</label>
                      </div>
                    </div>
                  </div>
                  {errors.examOptionHasError && (
                    <small className="text-danger">
                      Examination type is required
                    </small>
                  )}
                </div>
              </div>
            )}
            {showWaitngListForm && (
              <div className="row ">
                <div className="col-12">
                  <TextField
                    fullWidth
                    size="small"
                    name="numberOfPosts"
                    label="Number of staff needed"
                    variant="outlined"
                    placeholder="Number of staff needed"
                    value={waitingListRequestForm.numberOfPosts}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        numberOfPostsHasError: false,
                        isNumberOfPostsEqualToZero: false,
                        numberOfVacantPostsIsLessThanEntered: false,
                      });

                      setWaitingListRequestForm({
                        ...waitingListRequestForm,
                        numberOfPosts: e.target.value,
                      });
                    }}
                  />

                  {errors.numberOfPostsHasError && (
                    <small className="text-danger">
                      Number of posts is required{" "}
                    </small>
                  )}
                  {!errors.numberOfPostsHasError &&
                    errors.isNumberOfPostsEqualToZero && (
                      <small className="text-danger">
                        Number of posts must greater than zero
                      </small>
                    )}
                  {errors.numberOfVacantPostsIsLessThanEntered && (
                    <small className="text-danger">
                      Only {position.numberOfVacantPosts} vacant post
                      {position.numberOfVacantPosts > 1 ? "s are " : " is "}
                      available
                    </small>
                  )}
                </div>

                <div className="col-12 mt-3">
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="reason"
                    autoFocus
                    label="Motivate your request"
                    variant="outlined"
                    placeholder="Motivate your request"
                    value={waitingListRequestForm.reason}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setErrors({ ...errors, reasonHasError: false });

                      setWaitingListRequestForm({
                        ...waitingListRequestForm,
                        reason: e.target.value,
                      });
                    }}
                  />

                  {errors.reasonHasError && (
                    <small className="text-danger">
                      Please, motivate your request
                    </small>
                  )}
                </div>
              </div>
            )}

            <div className="mt-4 text-center">
              {showWaitngListForm && (
                <Button
                  className="btn-block"
                  variant="contained"
                  onClick={onSave}
                  type="button"
                  disabled={loading}
                >
                  {loading ? "Wait..." : "Request from waiting list"}
                </Button>
              )}

              {!showWaitngListForm && (
                <Button
                  className="btn-block"
                  variant="contained"
                  onClick={onSave}
                  type="button"
                  disabled={loading}
                >
                  {loading ? "Wait..." : "Request to advertize"}
                </Button>
              )}
              {!!position.numberOnWaitingList && (
                <>
                  <span className="mt-1 d-block text-center">or</span>

                  <button
                    type="button"
                    onClick={() => setShowWaitngListForm(!showWaitngListForm)}
                    className="btn mt-0 btn-sm btn-link"
                  >
                    {!!showWaitngListForm
                      ? "Advertize the position"
                      : "Use the waiting list"}
                  </button>
                </>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  saveRequisition,
})(AddRequisitionDialog);
